<template>
  <aside class="menu roboto">
    <div class="menu-head" >
      <header class=" min-w5 flex flex-column menu-head-top">
        <MetaGoLogo></MetaGoLogo>
<!--        <div class="menu-logo h2">-->
<!--          <span @click="$router.push('/index')" class="menu-title">-->
<!--            <img id="metago-logo" src="../../public/static/img/logo.png">-->
<!--          </span>-->
<!--        </div>-->
        <div class="pl4 pr3 mr3 w5 mb4 menu-content bzbb">
          <div id="new-document-btn">
            <el-button class="new-document" data-tag-id="create-new-document" type="primary" cancelcustom @click="$router.push('/new-document')" >新建文档</el-button>
          </div>
          <!--        <el-button class="search-btn" @click="$router.push('/advanced-search')"><i class="el-icon el-icon-search" style="margin-right: 10px"></i>搜索</el-button>-->

        </div>
      </header>


      <div class="pl4 w5 min-w5 flex-grow-1 flex-1 overflow-y-auto bzbb">
        <nav class="pr3 text-align-left">
          <section class="mt2 pt1">
            <div class="ember-view pointer hover-concord-dark-blue inline-flex no-underline mv2 pl3  w-100" @click="gotoPage('contractIndex')" :class="{'concord-blue':activeIndex==1,'mid-gray':activeIndex!=1}">
              <i aria-hidden="true" class="material-icons-outlined md-size1 mr2">
                dashboard
              </i>
              <span class="lh-normal ml1" data-tag-id="left-side-contract-index">合同主页</span>
            </div>
            <div class="ember-view pointer hover-concord-dark-blue inline-flex no-underline mv2 pl3  w-100" @click="gotoPage('files')" :class="{'concord-blue':activeIndex==2,'mid-gray':activeIndex!=2}">
              <i aria-hidden="true" class="material-icons-outlined md-size1 mr2">
                insert_drive_file
              </i>
              <span class="lh-normal ml1" data-tag-id="left-side-contract-files">文档列表</span>
            </div>
            <div class="ember-view pointer hover-concord-dark-blue inline-flex no-underline mv2 pl3  w-100" @click="gotoPage('deadline')" :class="{'concord-blue':activeIndex==3,'mid-gray':activeIndex!=3}">
              <i aria-hidden="true" class="material-icons md-size1 mr2">
                date_range
              </i>
              <span class="lh-normal ml1" data-tag-id="left-side-contract-deadline">时间提醒</span>
            </div>
            <div class="ember-view pointer hover-concord-dark-blue inline-flex no-underline mv2 pl3  w-100" @click="gotoPage('activities')" :class="{'concord-blue':activeIndex==4,'mid-gray':activeIndex!=4}">
              <i aria-hidden="true" class="material-icons md-size1 mr2">
                schedule
              </i>
              <span class="lh-normal ml1" data-tag-id="left-side-contract-activities">动态</span>
            </div>

            <div class="ember-view pointer hover-concord-dark-blue inline-flex no-underline mv2 pl3  w-100" :class="{'concord-blue':activeIndex==4,'mid-gray':activeIndex!=4}">
              <span class="bb w-100 b--black-10 "></span>
            </div>
          </section>

          <section class="mt2">
            <a  id="ember623" class="ember-view hover-concord-dark-blue inline-flex no-underline mv2 pl3  w-100" data-test-approval-workflows="" @click="gotoPage('approvals')" :class="{'concord-blue':activeIndex==5,'mid-gray':activeIndex!=5}">
              <i aria-hidden="true" class="material-icons md-size1 mr2">
                call_split
              </i>
              <span class="lh-normal ml1" data-tag-id="left-side-contract-approval">
            审批流程
          </span>
            </a>
            <!--href="#/contract/document-reports"-->
            <a  id="ember624" class="ember-view hover-concord-dark-blue inline-flex no-underline mv2 pl3   w-100" data-test-document-reports="" @click="gotoPage('teamStatistic')" :class="{'concord-blue':activeIndex==6,'mid-gray':activeIndex!=6}">
              <i aria-hidden="true" class="material-icons md-size1 mr2">
                library_books
              </i>

                <span class="lh-normal ml1" data-tag-id="left-side-contract-statistics">
                  统计
                </span>

            </a>

          </section>
        </nav>
      </div>

    </div>



      <leftMenuFooter ref="leftMenuFooter"></leftMenuFooter>
<!--      <el-dropdown trigger="click" placement="top" >-->
<!--      <div style="width: 100%">-->
<!--      <div class="ml4" style="height: 60px; display: flex;justify-content: space-between;width: 100%">-->
<!--        -->
<!--        <i class="el-icon-setting md-size2 v-mid mr2"></i>-->
<!--        <span class= "gray" style="font-size: 16px;flex: 1">-->
<!--          MetaSota<el-icon class="el-icon&#45;&#45;right"><arrow-down /></el-icon>-->
<!--        </span>-->
<!--        <i class="el-icon-d-caret" style="margin-right: 10px"></i>-->
<!--      </div>-->
<!--      </div>-->
<!--        <template #dropdown>-->
<!--        </template>-->
<!--      </el-dropdown>-->

  </aside>

</template>

<script>
import leftMenuFooter from "../components/LeftMenuFooter";
import MetaGoLogo from "./universalUI/MetaGoLogo";
import {colorLog} from "../util";
import {globalTagClick} from "../assets/js/tag/tag";
export default {
  name: "ContractSideMenu",
  components: {MetaGoLogo, leftMenuFooter },
  props:["activeIndex"],
  data() {
    return {
      isCollapse: false,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      openeds:['1'],
      refreshCount: false
    }
  },
  methods: {
    showWhatsNew(){
        this.$refs.whatsNew.className="whats-new whats-new-show"
    },
    hideWhatsNew(){
      this.$refs.whatsNew.className="whats-new whats-new-hide"
    },
    gotoPage:function (name){
      this.$router.push({name:name})
    }
  },
  watch: {
    activeIndex: {
      handler(val){
        this.$nextTick(() => {
          this.$refs["leftMenuFooter"].changeActiveIndex(val)
        })
      },
      deep: true,
      immediate: true
    },
    refreshCount: {
      handler(){
        this.$nextTick(() => {
          this.$refs["leftMenuFooter"].initMessagesCount()
          this.$refs["leftMenuFooter"].initTableData()
          this.refreshCount = false
        })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
@import "/src/assets/css/leftSideMenu.css";
.material-icons[class]{
  display: initial;
}
.menu{
  --meta-log-height:2.6rem;
}

.menu-content{
}
.el-menu{
  background-color: rgb(248, 249, 250);
}

.el-menu *{

  background-color: rgb(248, 249, 250);
}

.el-submenu:hover{
  background-color: rgb(248, 249, 250) ;
}

.el-submenu__title{
  display: flex;
  padding: 1rem;
}

.el-menu-item{
  text-align: left;
  padding-left: 0px !important;
  height: 30px !important;
  line-height: 30px !important;
}
.el-submenu__title:hover{
  background-color: rgb(248, 249, 250) ;
}

.el-menu-item:hover {
  background-color: rgb(248, 249, 250) ;
}


.el-menu *:hover{
  color: #1E9FFF;
}

.mr2{
  margin-right: .5rem;
}

.ml1{
  margin-left: .25rem;
}


element.style {
}
.mr2 {
  margin-right: .5rem;
}
.ml1 {
  margin-left: .25rem;
}
.pv1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.new-document{
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  /*background: linear-gradient(90deg, #67bdff 0, #297fff 100%);*/
  border-radius: 0;
}

.new-document:hover{
}

.mb3{
  margin-bottom: 1rem;
}
.min-w5 {
  min-width: 224px;
}

.mr3 {
  margin-right: 14px;
}
.pt4 {
  padding-top: 2rem;
}
.pr3 {
  padding-right: 14px;
}
.pl4 {
  padding-left: 28px;
}
.w5 {
  width: 224px;
}

.mt5 {
  margin-top: 3rem;
}

.mb4 {
  margin-bottom: 2rem;
}
.pt2 {
  padding-top: .5rem;
}

.search-btn{
  width: 100%;
  background-color: #EEE;
  color: #999;
  border: 0;
}

element.style {
}
.concord-blue, .hover-concord-blue:focus, .hover-concord-blue:hover {
  color: #5280FF;
}
.no-underline {
  text-decoration: none;
}
.pb3 {
  padding-bottom: 1rem;
}
.mid-gray {
  color: #555;
}
.w-100 {
  width: 100%;
}
.dib {
  display: inline-block;
}

a{
  cursor: pointer;
}

.stage-circle{
  width: 10px;
  height: 10px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
}

.stage-blue{
  background-color: #80cbff;
}

.stage-white{
  background-color: #e5e5e5;
}

.stage-gray{
  background-color: #bbbbbb;
}
.stage-yellow{
  background-color: #ffd459;
}
.stage-red{
  background-color: #ff8c8c;
}
.stage-green{
  background-color: #2CC6AB;
}
.stage-black{
  background-color: #636363;
}

.stage-purple{
  background-color: #c297ef;
}
.mr2 {
  margin-right: .5rem;
}
.ml4{
  margin-left: 2rem;
}
#new-document-btn  .el-button{
  min-height: 2.5rem !important;
  padding: 0 20px !important;
}

.b--black-10 {
  border-color: rgba(0,0,0,.1);
}
.el-submenu .el-menu-item{
  min-width:170px !important;
}

</style>
